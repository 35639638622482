export const fonts = {
  commons: 'emoji character-grid js-character-grid ',
  letters: 'system character-grid js-character-grid',
  stars: 'system character-grid js-character-grid',
  bullets: 'emoji character-grid js-character-grid',
  numbers: 'emoji character-grid js-character-grid',
  symbols: 'system character-grid js-character-grid',
  arrows: 'system character-grid js-character-grid',
  emojiSignals: 'emoji character-grid js-character-grid ',
  emojiObjects: 'emoji character-grid js-character-grid',
  emojiFaces: 'emoji character-grid js-character-grid',
  emojis: 'emoji character-grid js-character-grid',
  graphics: 'emoji character-grid js-character-grid',
  punctuations: 'emoji character-grid js-character-grid',
  accented: 'emoji character-grid js-character-grid',

}


export const chars = {
    commons: [],
    bullets: [
      '⚫',
      '⬛',
      '◼',
      '◾',
      '▪',
      '⏹',
      '⏺',
      '⬜',
      '◻',
      '◽',
      '▫',
      '🔲',
      '🔳',
      '💠',
      '🔘',
      '·',
      '•',
      '●',
      '∎',
      '‣',
      '🔶',
      '🔷',
      '🔸',
      '🔹',
      '🔺',
      '🔻',
      '✅',
      '✴',
      '❇',
      '✳',
      '*️⃣',
      '☐',
      '☑',
      '☒',
      '☓',
      '☛',
      '☚',
      '☜',
      '☞',
      '☟',
      '✌',
      '☝',
      '✍',
      '✏',
      '✐',
      '✑',
      '✒',
      '✎',
      '✕',
      '✖',
      '✗',
      '✘',
      '✓',
      '✔',
      '✛',
      '✜',
      '✝',
      '✞',
      '✟',
      '✠',
      '✡',
      '✣',
      '✤',
      '✥',
      '✢',
      '✣',
      '✙',
      '✚',
      '★',
      '☆',
      '⚝',
      '☀',
      '☼',
      '✦',
      '✧',
      '✩',
      '✪',
      '✫',
      '✬',
      '✭',
      '✮',
      '✯',
      '✰',
      '✱',
      '✲',
      '✳',
      '✴',
      '✵',
      '✶',
      '✷',
      '✸',
      '✹',
      '✺',
      '✻',
      '✼',
      '✽',
      '✾',
      '✿',
      '❀',
      '❁',
      '❂',
      '❃',
      '❄',
      '❅',
      '❆',
      '❇',
      '❉',
      '❋',
      '⚬',
      '⚭',
      '☉',
      '❍',
      '❏',
      '❐',
      '❑',
      '❒',
      '❖',
      '❘',
      '❙',
      '❚',
      '☙',
      '→'
    ],
    letters: [
      '🄰',
      '🄱',
      '🄲',
      '🄳',
      '🄴',
      '🄵',
      '🄶',
      '🄷',
      '🄸',
      '🄹',
      '🄺',
      '🄻',
      '🄼',
      '🄽',
      '🄾',
      '🄿',
      '🅀',
      '🅁',
      '🅂',
      '🅃',
      '🅄',
      '🅅',
      '🅆',
      '🅇',
      '🅈',
      '🅉',
      '🅰',
      '🅱',
      '🅲',
      '🅳',
      '🅴',
      '🅵',
      '🅶',
      '🅷',
      '🅸',
      '🅹',
      '🅺',
      '🅻',
      '🅼',
      '🅽',
      '🅾',
      '🅿',
      '🆀',
      '🆁',
      '🆂',
      '🆃',
      '🆄',
      '🆅',
      '🆆',
      '🆇',
      '🆈',
      '🆉',
      'Ⓐ',
      'Ⓑ',
      'Ⓒ',
      'Ⓓ',
      'Ⓔ',
      'Ⓕ',
      'Ⓖ',
      'Ⓗ',
      'Ⓘ',
      'Ⓙ',
      'Ⓚ',
      'Ⓛ',
      'Ⓜ',
      'Ⓝ',
      'Ⓞ',
      'Ⓟ',
      'Ⓠ',
      'Ⓡ',
      'Ⓢ',
      'Ⓣ',
      'Ⓤ',
      'Ⓥ',
      'Ⓦ',
      'Ⓧ',
      'Ⓨ',
      'Ⓩ',
      'ⓐ',
      'ⓑ',
      'ⓒ',
      'ⓓ',
      'ⓔ',
      'ⓕ',
      'ⓖ',
      'ⓗ',
      'ⓘ',
      'ⓙ',
      'ⓚ',
      'ⓛ',
      'ⓜ',
      'ⓝ',
      'ⓞ',
      'ⓟ',
      'ⓠ',
      'ⓡ',
      'ⓢ',
      'ⓣ',
      'ⓤ',
      'ⓥ',
      'ⓦ',
      'ⓧ',
      'ⓨ',
      'ⓩ',
      '🅐',
      '🅑',
      '🅒',
      '🅓',
      '🅔',
      '🅕',
      '🅖',
      '🅗',
      '🅘',
      '🅙',
      '🅚',
      '🅛',
      '🅜',
      '🅝',
      '🅞',
      '🅟',
      '🅠',
      '🅡',
      '🅢',
      '🅣',
      '🅤',
      '🅥',
      '🅦',
      '🅧',
      '🅨',
      '🅩',
      '🇦',
      '🇧',
      '🇨',
      '🇩',
      '🇪',
      '🇫',
      '🇬',
      '🇭',
      '🇮',
      '🇯',
      '🇰',
      '🇱',
      '🇲',
      '🇳',
      '🇴',
      '🇵',
      '🇶',
      '🇷',
      '🇸',
      '🇹',
      '🇺',
      '🇻',
      '🇼',
      '🇽',
      '🇾',
      '🇿',
    ],
    symbols: [
      '✆',
      '✉',
      '⚠',
      '⚙',
      '✈',
      '⚖',
      '✇',
      '⚛',
      '☸',
      '☮',
      '☯',
      '☠',
      '♛',
      '♚',
      '♜',
      '♝',
      '♞',
      '♟',
      '♥',
      '♦',
      '♠',
      '♣',
      '♕',
      '♔',
      '♖',
      '♗',
      '♘',
      '♙',
      '♡',
      '♢',
      '♤',
      '♧',
      '♩',
      '♪',
      '♫',
      '♬',
      '♭',
      '♮',
      '♯',
      '⚢',
      '⚣',
      '⚤',
      '⚥',
      '⚦',
      '⚧',
      '⚨',
      '⚩',
      '⛢',
      '☿',
      '♀',
      '♁',
      '♂',
      '♻',
      '♼',
      '♽',
      '♲',
      '♺',
      '☢',
      '☣',
      '♾',
      '⚀',
      '⚁',
      '⚂',
      '⚃',
      '⚄',
      '⚅',
      '⚒',
      '⚝',
      '⚕',
      '⚘',
      '⚚',
      '☭',
      '♨',
      '☤',
      '☥',
      '☦',
      '☧',
      '☨',
      '☩',
      '☪',
      '☫',
      '☬',
      '♇',
      '♃',
      '♄',
      '♅',
      '♆',
      '♰',
      '♱',
      '☾',
      '☽',
      '⚔',
      '🏹',
      '☊',
      '☋',
      '☌',
      '☍',
      '',
      '☎',
      '☏',
      '☂',
      '⚰',
      '⚱',
      '⚲',
      '⚾',
      '✁',
      '✂',
      '✃',
      '✄',
      '☘',
      '©',
      '®',
      '℗',
      '™',
      '@',
      '℃',
      '℉',
      '№',
      '⌘',
      '🏁',
      '🚩',
      '🏴',
      '🏳',
      '🏴‍☠️',
      '🏧',
      '🚾',
      '🅿',
      '📳',
      '⛔',
      '🚫',
      '⭕'
    ],
    arrows: [
      '▶',
      '⏩',
      '⏭',
      '⏯',
      '◀',
      '⏪',
      '⏮',
      '🔼',
      '⏫',
      '🔽',
      '⏬',
      '🔀',
      '🔁',
      '🔂',
      '⬆',
      '↗',
      '➡',
      '↘',
      '⬇',
      '🔺',
      '🔻',
      '↔',
      '↕',
      '↖',
      '↗',
      '↘',
      '↙',
      '↩',
      '↪',
      '⤴',
      '⤵',
      '☇',
      '☈',
      '←',
      '↑',
      '→',
      '↓',
      '↚',
      '↛',
      '↜',
      '↝',
      '↞',
      '↟',
      '↠',
      '↡',
      '↢',
      '↣',
      '↤',
      '↥',
      '↦',
      '↧',
      '↨',
      '↫',
      '↬',
      '↭',
      '↮',
      '↯',
      '↰',
      '↱',
      '↲',
      '↳',
      '↴',
      '↵',
      '↶',
      '↷',
      '↸',
      '↹',
      '↺',
      '↻',
      '↼',
      '↽',
      '↾',
      '↿',
      '⇀',
      '⇁',
      '⇂',
      '⇃',
      '⇄',
      '⇅',
      '⇆',
      '⇇',
      '⇈',
      '⇉',
      '⇊',
      '⇋',
      '⇌',
      '⇍',
      '⇎',
      '⇏',
      '⇐',
      '⇑',
      '⇒',
      '⇓',
      '⇔',
      '⇕',
      '⇖',
      '⇗',
      '⇘',
      '⇙',
      '⇚',
      '⇛',
      '⇜',
      '⇝',
      '⇞',
      '⇟',
      '⇠',
      '⇡',
      '⇢',
      '⇣',
      '⇤',
      '⇥',
      '⇦',
      '⇧',
      '⇨',
      '⇩',
      '⇪',
      '⇫',
      '⇬',
      '⇭',
      '⇮',
      '⇯',
      '⇰',
      '⇱',
      '⇲',
      '⇳',
      '⇴',
      '⇵',
      '⇶',
      '⇷',
      '⇸',
      '⇹',
      '⇺',
      '⇻',
      '⇼',
      '⇽',
      '⇾',
      '⇿',
      '⟰',
      '⟱',
      '⟲',
      '⟳',
      '⟴',
      '⟵',
      '⟶',
      '⟷',
      '⟸',
      '⟹',
      '⟺',
      '⟻',
      '⟼',
      '⟽',
      '⟾',
      '⟿',
      '⤀',
      '⤁',
      '⤂',
      '⤃',
      '⤄',
      '⤅',
      '⤆',
      '⤇',
      '⤈',
      '⤉',
      '⤊',
      '⤋',
      '⤌',
      '⤍',
      '⤎',
      '⤏',
      '⤐',
      '⤑',
      '⤒',
      '⤓',
      '⤔',
      '⤕',
      '⤖',
      '⤗',
      '⤘',
      '⤙',
      '⤚',
      '⤛',
      '⤜',
      '⤝',
      '⤞',
      '⤟',
      '⤠',
      '⤡',
      '⤢',
      '⤣',
      '⤤',
      '⤥',
      '⤦',
      '⤧',
      '⤨',
      '⤩',
      '⤪',
      '⤫',
      '⤬',
      '⤭',
      '⤮',
      '⤯',
      '⤰',
      '⤱',
      '⤲',
      '⤳',
      '⤶',
      '⤷',
      '⤸',
      '⤹',
      '⤺',
      '⤻',
      '⤼',
      '⤽',
      '⤾',
      '⤿',
      '⥀',
      '⥁',
      '⥂',
      '⥃',
      '⥄',
      '⥅',
      '⥆',
      '⥇',
      '⥈',
      '⥉',
      '⥊',
      '⥋',
      '⥌',
      '⥍',
      '⥎',
      '⥏',
      '⥐',
      '⥑',
      '⥒',
      '⥓',
      '⥔',
      '⥕',
      '⥖',
      '⥗',
      '⥘',
      '⥙',
      '⥚',
      '⥛',
      '⥜',
      '⥝',
      '⥞',
      '⥟',
      '⥠',
      '⥡',
      '⥢',
      '⥣',
      '⥤',
      '⥥',
      '⥦',
      '⥧',
      '⥨',
      '⥩',
      '⥪',
      '⥫',
      '⥬',
      '⥭',
      '⥮',
      '⥯',
      '⥰',
      '⥱',
      '⥲',
      '⥳',
      '⥴',
      '⥵',
      '⥶',
      '⥷',
      '⥸',
      '⥹',
      '⥺',
      '⥻',
      '⥼',
      '⥽',
      '⥾',
      '⥿',
      '➔',
      '➘',
      '➙',
      '➚',
      '➛',
      '➜',
      '➝',
      '➞',
      '➟',
      '➠',
      '➢',
      '➣',
      '➤',
      '➥',
      '➦',
      '➧',
      '➨',
      '➩',
      '➪',
      '➫',
      '➬',
      '➭',
      '➮',
      '➯',
      '➱',
      '➲',
      '➳',
      '➴',
      '➵',
      '➶',
      '➷',
      '➸',
      '➹',
      '➺',
      '➻',
      '➼',
      '➽',
    ],
    stars: [
      '★',
      '☆',
      '⚝',
      '☀',
      '☼',
      '🔅',
      '🔆',
      '🌝',
      '🌞',
      '⭐',
      '🌟',
      '✦',
      '✧',
      '✩',
      '✪',
      '✫',
      '✬',
      '✭',
      '✮',
      '✯',
      '✰',
      '✱',
      '✲',
      '✳',
      '✴',
      '✵',
      '✶',
      '✷',
      '✸',
      '✹',
      '✺',
      '✻',
      '✼',
      '✽',
      '✾',
      '✿',
      '❀',
      '❁',
      '❂',
      '❃',
      '❄',
      '❅',
      '❆',
      '❇',
      '❉',
      '❋'
    ],
    emojiSignals: [
      '©',
      '®',
      '℗',
      '™',
      '@',
      '℃',
      '℉',
      '№',
      '⌘',
      '🏁',
      '🚩',
      '🏴',
      '🏳',
      '🏴‍☠️',
      '🏧',
      '🚾',
      '🅿',
      '📳',
      '⛔',
      '🚫',
      '⭕'
    ],
    emojiObjects: [
      '🎉',
      '🎁',
      '🎖',
      '🏆',
      '🏅',
      '🥇',
      '🎊',
      '🔔',
      '🔕',
      '💡',
      '☎',
      '📞',
      '📱',
      '🔍',
      '📀',
      '🖥',
      '💻',
      '🎧',
      '🔈',
      '📻',
      '🔌',
      '🖨',
      '🔑',
      '🔒',
      '🔓',
      '🔏',
      '🔐',
      '🗝',
      '🔧',
      '🔨',
      '⛏',
      '⚒',
      '🛠',
      '🔩',
      '🗜',
      '🔗',
      '⚙',
      '📌',
      '📍',
      '📎',
      '🖇',
      '📏',
      '📐',
      '✂',
      '🗃',
      '🗄',
      '✏',
      '✒',
      '🖋',
      '🖊',
      '🖌',
      '🖍',
      '👑',
      '🎓',
      '⛑',
      '🎩',
      '⚽',
      '🏀',
      '🏈',
      '🎾',
      '✉',
      '📨',
      '📩',
      '💊',
      '💉',
      '🔬',
      '⛱',
      '🚿',
      '💵',
      '💶'
    ],
    emojiFaces: [
      '☹',
      '☺',
      '😀',
      '😂',
      '😃',
      '😄',
      '😉',
      '😊',
      '😍',
      '😓',
      '😭',
      '😇',
      '😘',
      '😜',
      '😴',
      '😎',
      '😀',
        '😃',
        '😄',
        '😁',
        '😆',
        '😅',
        '🤣',
        '😂',
        '🙂',
        '🙃',
        '😉',
        '😊',
        '😗',
        '☺',
        '😚',
        '😙',
        '😋',
        '😛',
        '😜',
        '😝',
        '🤑',
        '🤗',
        '🤔',
        '🤐',
        '😐',
        '😑',
        '😶',
        '😏',
        '😒',
        '🙄',
        '😬',
        '🤥',
        '😌',
        '😔',
        '😪',
        '🤤',
        '😴',
        '😷',
        '🤒',
        '🤕',
        '🤢',
        '🤧',
        '😵',
        '🤠',
        '😎',
        '🤓',
        '😕',
        '😟',
        '🙁',
        '☹',
        '😮',
        '😯',
        '😲',
        '😳',
        '😦',
        '😧',
        '😨',
        '😰',
        '😥',
        '😢',
        '😭',
        '😱',
        '😖',
        '😣',
        '😞',
        '😓',
        '😩',
        '😫',
        '😤',
        '😡',
        '😠',
        '🤬',
        '😈',
        '👿',
        '💀',
        '☠',
    ],
    numbers: [
      '0️⃣',
      '1️⃣',
      '2️⃣',
      '3️⃣',
      '4️⃣',
      '5️⃣',
      '6️⃣',
      '7️⃣',
      '8️⃣',
      '9️⃣',
      '🔟',
      '⓪',
      '①',
      '②',
      '③',
      '④',
      '⑤',
      '⑥',
      '⑦',
      '⑧',
      '⑨',
      '⓵',
      '⓶',
      '⓷',
      '⓸',
      '⓹',
      '⓺',
      '⓻',
      '⓼',
      '⓽',
      '⓾',
      '♳',
      '♴',
      '♵',
      '♶',
      '♷',
      '♸',
      '♹',
    ],
  }
