import React from "react";

const PATREON_LINK = "http://patreon.com/Robin_Good";

class CustomNav extends React.Component {
  render() {
    const { language } = this.props;

    return (
      <div className="custom-nav">
        <div className="container-fluid">
          <div className="row align-content-end">
            <div className="col-lg-2 col-md-2 col-12 d-flex">
              <p className="titolo">Icon</p>
              <p className="titolo2">Box</p>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <span className="sottotitolo">
                by{" "}
                <a
                  href={"https://robingood.substack.com"}
                  target="_BLANK"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}>
                  {" "}
                  Robin Good{" "}
                  <img
                    src="/images/robin-icon.png"
                    alt=""
                    width="36"
                    height="36"
                    className="robinFace"
                  />
                </a>
              </span>
            </div>
            <div className="col-lg-6 col-md-6 col-12 position-relative">
              <span className="sottotitolo postition-bottom">
                {language.description}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomNav;
