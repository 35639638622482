import React, { Component } from "react";
import { chars, fonts } from "../data/chars";
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "react-s-alert/dist/s-alert-css-effects/flip.css";
import data from "../data/data.json";

function makeid(length) {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

const renderChar = (el, func) => {
  if (el)
    return (
      <div
        className="character"
        title={el.name}
        key={makeid(10)}
        onClick={() => func(el)}>
        <span className="symbol js-character" data-clipboard-text={el}>
          <span>{el.emoji}</span>
        </span>
      </div>
    );
};

export default class CharGrid extends Component {
  copyOnClipboard = da => {
    const el = document.createElement("textarea");
    el.value = da.emoji;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    console.log("copiato");

    if (data.commons.find(x => x.emoji === da.emoji)) {
      return Alert.success(this.props.language.message(da.emoji), {
        effect: "flip",
        beep: false,
        timeout: 3000
      });
    } else {
      data.commons.push(da);

      localStorage.setItem("commons", JSON.stringify(chars.commons));

      Alert.success(this.props.language.message(da.emoji), {
        effect: "flip",
        beep: false,
        timeout: 3000
      });
    }
  };

  copyToClipboard = da => {
    // resolve the element
    console.log(da);
    const el = document.createElement("textarea");
    el.value = da.emoji;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);

    // el = document.querySelector(el)
    // handle iOS as a special case
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      // save current contentEditable/readOnly status
      var editable = el.contentEditable;
      var readOnly = el.readOnly;
      // convert to editable with readonly to stop iOS keyboard opening
      el.contentEditable = true;
      el.readOnly = true;
      // create a selectable range
      var range = document.createRange();
      range.selectNodeContents(el);
      // select the range
      var selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      el.setSelectionRange(0, 999999);
      // restore contentEditable/readOnly to original state
      el.contentEditable = editable;
      el.readOnly = readOnly;
    } else {
      el.select();
    }

    // execute copy command
    document.execCommand("copy");
    document.body.removeChild(el);

    if (data.commons.find(x => x.emoji === da.emoji)) {
      return Alert.success(this.props.language.message(da.emoji), {
        effect: "flip",
        beep: false,
        timeout: 3000
      });
    } else {
      data.commons.push(da);

      localStorage.setItem("commons", JSON.stringify(chars.commons));

      Alert.success(this.props.language.message(da.emoji), {
        effect: "flip",
        beep: false,
        timeout: 3000
      });
    }
  };

  componentWillMount() {
    const a = JSON.parse(localStorage.getItem("commons"));
    if (a) {
      // eslint-disable-next-line  array-callback-return
      a.map(e => {
        if (!chars.commons.find(x => x === e)) return chars.commons.push(e);
      });
    }
  }

  render() {
    const arr = data[this.props.type];

    return (
      <div className={fonts[this.props.type]}>
        {arr.map(e => renderChar(e, this.copyToClipboard))}
      </div>
    );
  }
}
