import React, { Component, useState, useEffect } from 'react';
import Tabs from './components/Tabs';

import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/flip.css';
import CustomNav from './components/CustomNav';
import detectBrowserLanguage from 'detect-browser-language';
import { Container, Grid, Header, Image, Segment } from 'semantic-ui-react';

import data from './data/data.json';
import { chars, fonts } from './data/chars';

import { italian, english } from './data/languages';
import './App.css';

class App extends Component {
	state = {
		term: null,
	};

	setTerm = (val) => {
		this.setState({ term: val });
	};

	render() {
		return (
			<div className="App">
				<Alert position="top" />
				<CustomNav language={detectBrowserLanguage() == 'it-IT' ? italian : english} />
				<br />
				<Search setTerm={this.setTerm} />
				<div style={{ height: '10px' }}></div>
				{this.state.term ? (
					<SearchResults term={this.state.term} />
				) : (
					<Tabs language={detectBrowserLanguage() === 'it-IT' ? italian : english} />
				)}
				<Footer></Footer>
			</div>
		);
	}
}

const PATREON_LINK = 'http://patreon.com/Robin_Good';

const SearchResults = ({ term }) => {
	const [results, setResults] = useState([]);

	useEffect(() => {
		const match = [];
		Object.values(data).map((items) => {
			return items.map((dt) => {
				if (dt) {
					if (dt.name.toLowerCase().includes(term.toLowerCase())) {
						match.push(dt);
					}
				}
			});
		});

		setResults(match);
	}, [term]);

	const makeid = (length) => {
		var text = '';
		var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

		for (var i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text;
	};

	const copyToClipboard = (da) => {
		// resolve the element

		const language = detectBrowserLanguage() === 'it-IT' ? italian : english;
		console.log(da);
		const el = document.createElement('textarea');
		el.value = da.emoji;
		el.setAttribute('readonly', '');
		el.style.position = 'absolute';
		el.style.left = '-9999px';
		document.body.appendChild(el);

		// el = document.querySelector(el)
		// handle iOS as a special case
		if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
			// save current contentEditable/readOnly status
			var editable = el.contentEditable;
			var readOnly = el.readOnly;
			// convert to editable with readonly to stop iOS keyboard opening
			el.contentEditable = true;
			el.readOnly = true;
			// create a selectable range
			var range = document.createRange();
			range.selectNodeContents(el);
			// select the range
			var selection = window.getSelection();
			selection.removeAllRanges();
			selection.addRange(range);
			el.setSelectionRange(0, 999999);
			// restore contentEditable/readOnly to original state
			el.contentEditable = editable;
			el.readOnly = readOnly;
		} else {
			el.select();
		}

		// execute copy command
		document.execCommand('copy');
		document.body.removeChild(el);

		if (data.commons.find((x) => x.emoji === da.emoji)) {
			return Alert.success(language.message(da.emoji), {
				effect: 'flip',
				beep: false,
				timeout: 3000,
			});
		} else {
			data.commons.push(da);

			localStorage.setItem('commons', JSON.stringify(chars.commons));

			Alert.success(language.message(da.emoji), {
				effect: 'flip',
				beep: false,
				timeout: 3000,
			});
		}
	};

	return (
		<div className="emoji character-grid js-character-grid">
			{results.length === 0 ? <p>No result found</p> : ''}

			{results.map((el) => {
				return (
					<div className="character" title={el.name} key={makeid(10)}>
						<span
							className="symbol js-character"
							data-clipboard-text={el}
							onClick={() => copyToClipboard(el)}>
							<span>{el.emoji}</span>
						</span>
					</div>
				);
			})}
		</div>
	);
};

const Search = ({ setTerm }) => {
	const [query, setQuery] = useState('');

	const handleChange = () => {
		setTerm(query);
	};

	const clearSearch = () => {
		setQuery('');
		setTerm(null);
	};

	return (
		<>
			<input
				icon="search"
				placeholder="Search..."
				className="form-control"
				value={query}
				onChange={(e) => setQuery(e.target.value)}
				style={{
					width: '350px',
					display: 'inline',
					height: '37px',
					borderRadius: '7px',
					margin: '10px',
				}}
				onKeyPress={(event) => {
					if (event.key === 'Enter') {
						handleChange();
					}
				}}
			/>
			<button onClick={handleChange} className="bn">
				Search
			</button>
			<button className="bn" onClick={clearSearch}>
				Clear{' '}
			</button>
		</>
	);
};
const Footer = ({ language }) => (
	<Segment inverted vertical style={{ padding: '3em 0em' }} className="footer-section custom-nav">
		<Container>
			<Grid divided inverted stackable>
				<Grid.Row className="center">
					<Grid.Column>
						<Header inverted as="h5" content={' '} />
						<React.Fragment>
							<a href={"https://robingood.substack.com"} target="_BLANK" rel="noopener noreferrer">
								<Image
									src="/images/logoTrustable.JPG"
									className="pateron-img"
									style={{ display: 'inline' }}
								/>
							</a>
						</React.Fragment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	</Segment>
);

export default App;
