export const italian = {
         message: emoji =>
           `Il carattere ${emoji} e' stato copiato nella tua clipboard`,
         description:
           "Accedi istantaneamente a tutti i simboli Unicode, dingbat, caratteri grafici + emoji. Fai un clic per copiare qualsiasi simbolo e averlo pronto per essere incollato nella tua app preferita",
         titleBullets: "Bullet",
         titleArrows: "Frecce",
         titleStars: "Stelle",
         titleSymbols: "Simboli",
         titleLetters: "Lettere",
         titleNumbers: "Numeri",
         titleObjects: "Oggetti",
         titleFaces: "Facce",
         titleFavorites: "Preferiti",
         titleEmojis: "Emojis",
         titleGraphics: "Shapes",
         titlePunctuation: "Marks",
         titleAccented: "Accented"
       };

export const english = {
         message: emoji =>
           `The character ${emoji} has been successfully copied in your clipboard`,
         description:
           "Instantly access all unicode symbols, dingbats, graphic characters + emojis. One click to copy any symbol and have it ready for  pasting in your favorite app",
         titleBullets: "Bullets",
         titleArrows: "Arrows",
         titleStars: "Stars",
         titleSymbols: "Symbols",
         titleLetters: "Letters",
         titleNumbers: "Numbers",
         titleObjects: "Objects",
         titleFaces: "Faces",
         titleFavorites: "Recent",
         titleEmojis: "Emojis",
         titleGraphics: "Shapes",
         titlePunctuation: "Marks",
         titleAccented: "Accented"
       };
