import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import CharGrid from "./CharGrid";

const RenderTab = element => {
  return (
    <Tab
      eventKey={element.key}
      title={element.title}
      key={element.key}
      className="p-12">
      {element.child()}
    </Tab>
  );
};

export default props => {
  const { language } = props;

  const tabs = [
    {
      title: language.titleBullets,
      key: "Bullets",
      child: () => <CharGrid type="bullets" language={language} />
    },
    {
      title: language.titleArrows,
      key: "Arrows",
      child: () => <CharGrid type="arrows" language={language} />
    },

    {
      title: language.titleStars,
      key: "Stars",
      child: () => <CharGrid type="stars" language={language} />
    },
    {
      title: language.titleSymbols,
      key: "Symbols",
      child: () => <CharGrid type="symbols" language={language} />
    },
    {
      title: language.titleLetters,
      key: "Letters",
      child: () => <CharGrid type="letters" language={language} />
    },
    {
      title: language.titleNumbers,
      key: "Numbers",
      child: () => <CharGrid type="numbers" language={language} />
    },
    {
      title: language.titleObjects,
      key: "Objects",
      child: () => <CharGrid type="emojiObjects" language={language} />
    },
    // {
    //   title: language.titleFaces,
    //   key: "Faces",
    //   child: () => <CharGrid type="emojiFaces" language={language} />
    // },
    {
      title: language.titleEmojis,
      key: "Emojis",
      child: () => <CharGrid type="emojis" language={language} />
    },
    {
      title: language.titleGraphics,
      key: "Graphics",
      child: () => <CharGrid type="graphics" language={language} />
    },
    {
      title: language.titlePunctuation,
      key: "Marks",
      child: () => <CharGrid type="punctuations" language={language} />
    },
    {
      title: language.titleAccented,
      key: "Accented",
      child: () => <CharGrid type="accented" language={language} />
    },
    {
      title: language.titleFavorites,
      key: "Last Used",
      child: () => <CharGrid type="commons" language={language} />
    }
  ];

  return (
    <Tabs defaultActiveKey="Bullets" id="uncontrolled-tab-example">
      {tabs.map(el => RenderTab(el))}
    </Tabs>
  );
};
